<script setup lang="ts">
import {ElMessage} from "element-plus";

defineProps<{
  open: boolean
  provider: string
}>()
defineEmits<{
  (e: 'onClose'): void
}>()

const number = ref<string>('')

async function onSearch(number: string) {
  if (!number) {
    return;
  }

  const {data: cert} = await useApiFetchCertByNumber(number)

  if (cert.value) {
    navigateTo({
      path: `/cert/${cert.value.id}`,
      // query: {
      //   number
      // }
    })
  } else {
    ElMessage.error('证书备案号有误')
  }
}

</script>

<template>
  <glx-modal :open="open">
    <section class="container">
      <button class="btnClose" @click="$emit('onClose')">
        <img src="/img/components/cert-number-modal/关闭弹窗.png" alt="" class="icon">
        <div class="text">
          关闭
        </div>
      </button>
      <div class="cardInfo">
        <img src="/img/components/cert-number-modal/装饰-箭头.png" alt="" class="iconArrow">
        <div class="titleWrapper">
          <div class="title">
            {{ provider }}
          </div>
        </div>
      </div>
      <div class="cardSearch">
        <div class="titleFloor">
          <img src="/img/components/cert-number-modal/查看证书.png" alt="" class="iconTitle">
        </div>
        <div class="searchWrapper">
          <input
              type="text"
              class="search"
              v-model="number"
              placeholder="输入证书备案号查看证书"
              @keydown.enter="onSearch(number)"
          >
          <img src="/img/components/cert-number-modal/搜索.png" alt="" class="iconSearch" @click="onSearch(number)">
        </div>
      </div>
    </section>
  </glx-modal>
</template>

<style scoped lang="scss">
section.container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 302px;


  .btnClose {
    width: 78px;
    height: 32px;
    border-radius: 16px;
    border: 1px solid rgba(#FFFFFF, 0.8);

    display: flex;
    justify-content: center;

    position: absolute;
    right: 0;
    top: -12px;
    transform: translateY(-100%);

    .icon {
      display: block;
      align-self: center;
    }

    .text {
      display: block;
      margin-left: 6px;
      font-family: Source Han Sans CN;
      font-weight: 300;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 32px;
    }
  }

  .cardInfo {
    width: 818px;
    height: 317px;
    border-radius: 4px;
    @include glx-bg-img('/img/components/cert-number-modal/查询到信息.png');

    position: relative;
    padding-top: 130px;

    > .iconArrow {
      width: 42px;
      height: 42px;
      position: absolute;
      bottom: -22px;
      left: 50%;
      transform: translateX(-50%);
    }

    > .titleWrapper {
      width: 720px;
      height: 128px;
      margin: 0 auto;
      padding-top: 35px;

      .title {
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-size: 62px;
        color: #FFFFFF;
        line-height: 1;
        text-align: center;
      }
    }
  }

  .cardSearch {
    width: 782px;
    height: 158px;
    background: #FFFFFF;
    border-radius: 0px 0px 4px 4px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2px;

    > .titleFloor {
      padding-top: 19px;
      padding-left: 34px;

      .iconTitle {
        width: 94px;
        height: 16px;
      }
    }

    > .searchWrapper {
      margin-top: 13px;
      width: 656px;
      height: 64px;
      background: #EDEEF5;
      border-radius: 4px;
      border: 1px solid #A7A9BD;
      margin-left: auto;
      margin-right: auto;
      padding-right: 25px;

      display: flex;

      input.search {
        flex-grow: 1;
        padding: 0 24px;
        background-color: transparent;

        &, &::placeholder {
          font-family: Source Han Sans CN;
          font-weight: 300;
          font-size: 18px;
          color: #7F7F83;
          line-height: 1;
        }
      }

      .iconSearch {
        flex-shrink: 0;
        width: 28px;
        height: 28px;
        display: block;
        align-self: center;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>