<script setup lang="ts">
const {data: newsList} = await useApiFetchPolicyNews()
const curIndex = ref(0)

const curItem = computed(() => {
  return newsList.value?.[curIndex.value]
})

function isActive(id: number) {
  return id === curItem.value.id
}
</script>

<template>
  <section class="block2">
    <div class="blockCenter">
      <img src="/img/home/block2-背景装饰-椭圆.png" alt="" class="bgDecorate1">
      <div class="titleWrapper">
        <img src="/img/home/block2-标题.png" alt="" class="imgTitle">
      </div>
      <section class="newsListBlock">
        <div class="imgPolicyWrapper">
          <img src="/img/home/block2-政策新闻图.png" alt="" class="imgPolicy">
        </div>
        <div class="newsList">
          <div class="news" v-for="(item, index) in newsList">
            <div
                class="header"
                :class="{active:isActive(item.id)}"
                @click="curIndex=index"
            >
              <div class="iconArrowWrapper">
                <img src="/img/home/block2-箭头-收起.png" alt="" class="iconArrow" v-if="!isActive(item.id)">
                <img src="/img/home/block2-箭头-展开.png" alt="" class="iconArrow" v-else>
              </div>
              <div class="titleWrapper">
                <div class="title">
                  {{ item.title }}
                </div>
              </div>
            </div>
            <div class="contentWrapper" :class="{active:isActive(item.id)}">
              <div v-html="item.content" class="content"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<style scoped lang="scss">
section.block2 {
  background-color: $color-block-bg;

  .blockCenter {
    @include glx-center-block;
    position: relative;
    padding-top: 204px;
    padding-bottom: 140px;
    z-index: 0;

    .bgDecorate1 {
      position: absolute;
      width: 748px;
      height: 748px;
      top: 162px;
      left: -100px;
      z-index: -1;
    }

    .titleWrapper {
      .imgTitle {
        width: 590px;
        height: 34px;
      }
    }

    .newsListBlock {
      height: 562px;
      margin-top: 44px;

      display: flex;
      justify-content: space-between;

      > .imgPolicyWrapper {
        .img {
          width: 566px;
          height: 562px;
        }
      }

      > .newsList {
        width: 620px;
        align-self: stretch;

        > .news {
          position: relative;

          &:not(:first-child) {
            margin-top: 5px;
          }

          > .header {
            min-height: 52px;
            box-sizing: border-box;
            padding-left: 30px;
            padding-right: 24px;
            background: #FFFFFF;

            position: relative;
            display: flex;

            &:hover {
              cursor: pointer;
            }

            > .iconArrowWrapper {
              padding-top: 17px;
              flex-shrink: 0;
              //position: absolute;

              .iconArrow {
                object-fit: none;
              }
            }

            > .titleWrapper {
              flex-grow: 1;
              box-sizing: border-box;
              padding-top: 14px;
              margin-left: 20px;
              width: 420px;


              > .title {
                font-family: PingFang SC;
                font-weight: 500;
                font-size: 24px;
                color: #000000;
                line-height: 1;


                overflow: hidden; //超出的文本隐藏
                text-overflow: ellipsis; //溢出用省略号显示
                white-space: nowrap; //溢出不换行

                //&.normal {
                //  overflow: hidden; //超出的文本隐藏
                //  text-overflow: ellipsis; //溢出用省略号显示
                //  white-space: nowrap; //溢出不换行
                //}

              }

            }

            &.active {
              height: 64px;

              > .iconArrowWrapper {
                padding-top: 30px;
              }

              > .titleWrapper {
                padding-top: 22px;

                > .title {
                  color: rgba(223, 50, 52, 1);
                  //line-height: 30px;
                }
              }
            }

            &:hover {
              > .titleWrapper {
                > .title {
                  color: rgba(223, 50, 52, 1);
                }
              }
            }
          }

          > .contentWrapper {
            display: none;
            //margin-top: 20px;
            padding-bottom: 22px;
            background-color: #FFFFFF;


            > .content {
              margin-left: 24px;
              margin-right: 30px;
              padding: 10px 27px 13px 20px;
              max-height: 0;
              //max-height: 210px;
              font-family: PingFang SC;
              font-weight: 400;
              font-size: 20px;
              color: rgba(#060606, 0.9);
              line-height: 30px;

              background: #F8F5F6;
              border-radius: 2px;

              overflow-y: scroll;

              //整个滚动条。
              &::-webkit-scrollbar {
                background-color: rgba(255, 255, 255, 0.06);
                width: 2px;
              }

              //滚动条轨道。
              &::-webkit-scrollbar-track {
                background-color: rgba(255, 255, 255, 0.06);
              }

              //滚动条上的滚动滑块。
              &::-webkit-scrollbar-thumb {
                width: 2px;
                background-color: rgba(255, 255, 255, 1);
                border: 0px solid #FFFFFF;
              }

              //滚动条上的滚动滑块。
              &::-webkit-scrollbar-thumb:hover {
                width: 2px;
                background-color: rgba(255, 255, 255, 1);
                border: 0px solid #FFFFFF;
              }

              //当同时有垂直滚动条和水平滚动条时交汇的部分。通常是浏览器窗口的右下角。
              &::-webkit-scrollbar-corner {
                background-color: rgba(255, 255, 255, 0.06);
              }

              //overflow: hidden;
              //text-overflow: ellipsis;
              //display: -webkit-box; //作为弹性伸缩盒子模型显示。
              //-webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
              //-webkit-line-clamp: 9; //显示的行
            }

            &.active {
              display: block;

              > .content {
                max-height: 194px;
                animation-name: open;
                animation-duration: 0.7s;
              }

              @keyframes open {
                0% {
                  max-height: 0;
                }

                100% {
                  max-height: 194px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>