<script setup lang="ts">
import ProtectionModal from "~/components/protection-modal/ProtectionModal.vue";
import VideoBg from "~/components/VideoBg/VideoBg.vue";

const config = useRuntimeConfig()
const appBaseUrl = config.app.baseURL

const keyword = ref<string>('')
const modalOpen = reactive({
  protection: false,
  certNotFound: false,
  certNumber: false
})

async function onSearch(keyword: string) {
  if (!keyword) {
    return;
  }
  const {data: isProtection} = await useApiProtectionExists(keyword);
  if (isProtection.value) {
    modalOpen.protection = true
    return;
  }

  const {data: certExist} = await useApiCertExist(keyword);
  if (certExist.value) {
    modalOpen.certNumber = true
  } else {
    modalOpen.certNotFound = true;
  }
}
</script>

<template>
  <section class="block1">
    <!--视频背景-->
    <video-bg
        :src="appBaseUrl + '/video/home/block1-视频背景.mp4'"
        :opacity="appBaseUrl + '/img/home/block1-背景.png'"
    />
    <glx-block container-class-name="glx-block-container">
      <section class="search">
        <img class="iconArrow" src="/img/home/block1-搜索框装饰.png" alt="">
        <div class="title">
          查询证书
        </div>
        <div class="searchWrapper">
          <input
              type="text"
              class="inputSearch"
              v-model="keyword"
              placeholder="输入证书关键词"
              @keydown.enter="onSearch(keyword)"
          >
          <img class="iconSearch" src="/img/home/block1-搜索.png" alt="" @click="onSearch(keyword)">
        </div>
        <div class="tipWrapper">
          <div class="tip">
            输入证书关键词查询证书，请保证输入的证书关键词有效 <em>*</em>
          </div>
        </div>
      </section>
    </glx-block>
    <protection-modal @on-close="modalOpen.protection = false" :name="keyword" :open="modalOpen.protection"/>
    <cert-not-found-modal @on-close="modalOpen.certNotFound = false" :open="modalOpen.certNotFound"/>
    <cert-number-modal :open="modalOpen.certNumber" @on-close="modalOpen.certNumber=false" :provider="keyword"/>
  </section>
</template>

<style scoped lang="scss">
section.block1 {
  position: relative;
  height: 766px;
  z-index: 1;

  :deep(.glx-block-container) {
    position: relative;
    z-index: 0;
  }

  section.search {
    width: 980px;
    height: 166px;
    background: rgba(251, 60, 60, 0.2);
    border: 2px solid #FB3C3C;
    position: absolute;
    bottom: -90px;
    left: 50%;
    transform: translateX(-50%);

    @include glx-bg-img("/img/home/block1-搜索框背景.png");
    z-index: 10;

    > .iconArrow {
      width: 62px;
      height: 62px;
      border-radius: 50%;
      display: block;
      position: absolute;

      left: -25px;
      top: 50%;
      transform: translateY(-50%);
    }

    .title {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #90EEFF;
      line-height: 1;

      padding-top: 30px;
      margin-left: 147px;
    }

    .searchWrapper {
      width: 708px;
      height: 48px;
      background: #F1F2F7;
      border-radius: 2px;
      border: 1px solid #D6D8E0;

      margin-top: 12px;
      margin-left: 147px;

      display: flex;
      align-items: stretch;
      padding-right: 20px;

      @mixin inputText() {
        font-family: Source Han Sans CN;
        font-weight: 300;
        font-size: 18px;
        color: #ACADB6;
        line-height: 1;
      }

      .inputSearch {
        flex-grow: 1;
        padding: 0 20px;
        background-color: transparent;

        @include inputText();

        &::placeholder {
          @include inputText();
        }
      }

      .iconSearch {
        align-self: center;
        flex-shrink: 0;
        width: 25px;
        height: 25px;

        display: block;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .tipWrapper {
      margin-top: 12px;
      margin-left: 147px;

      .tip {
        font-family: PingFang SC;
        font-weight: 300;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 1;
        opacity: 0.6;

        em {
          color: #FB3C3C;
        }
      }
    }
  }
}
</style>