<script setup lang="ts">
defineProps<{
  src: string
  opacity?: string
}>()

const videoRef = ref<HTMLVideoElement>();
const playFlag = ref(false)
const videoLoaded = ref(false)

watch(videoLoaded, (newValue) => {
  if (newValue) {
    videoRef.value?.play()
  }
})
</script>

<template>

  <div class="videoWrapper">
    <div class="imgOpacityWrapper">
      <img class="imgOpacity" :src="opacity" alt='' v-if="opacity"/>
    </div>

    <video
        class="video"
        ref={ref}
        width='100%'
        muted
        autoPlay
        loop
        @play="playFlag = true"
        @pause="playFlag = false"
        @load="videoLoaded = true"
    >
      <source :src="src" type="video/mp4"/>
    </video>
  </div>
</template>

<style scoped lang="scss">
.videoWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -10;
  overflow: hidden;

  .imgOpacityWrapper {
    @include glx-center-block();
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    z-index: 2;

    .imgOpacity {
      height: 100%;
      object-fit: none;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .video {
    //height: 100%;
    width: 100%;
    object-fit: contain;
    position: relative;
    z-index: 1;

    //z-index: 0;
    //position: absolute;
    //top: 0;
    //left: 50%;
    //transform: translateX(-50%);
  }


}
</style>