<script setup lang="ts">
//swiper 组件
import {Swiper, SwiperSlide} from 'swiper/vue';
// swiper css
import 'swiper/css';

const config = useRuntimeConfig()
const appBaseUrl = config.app.baseURL
type Item = {
  name: string
  img: string
}
const list = [
  {
    name: '24小时无人直播',
    img: '/img/home/block4-无人直播.png'
  },
  {
    name: '拼多多京东佣金产品助力',
    img: '/img/home/block4-佣金助力.png'
  },
  {
    name: 'GPT助力后台商品管理',
    img: '/img/home/block4-gpt助力.png'
  }
].map(item => {
  return {
    ...item,
    img: `${appBaseUrl}${item.img}`
  }
})

const curIndex = ref(0)
const curTab = computed(() => {
  return list[curIndex.value]
})

function indexIsActive(index: number) {
  return curIndex.value === index
}

function onClickTab(index: number) {
  curIndex.value = index
}

</script>

<template>
  <section class="block4">
    <div class="blockCenter">
      <div class="titleWrapper">
        <img src="/img/home/block4-人工智能商城.png" alt="" class="imgTitle">
      </div>
      <div class="tabFloor">
        <div class="tab" :class="{active:indexIsActive(index)}" v-for="(item, index) in list" :key="index"
             @click="onClickTab(index)">
          {{ item.name }}
        </div>
      </div>
      <div class="lineWrapper">
        <div class="line" :class="`line${curIndex + 1}`"></div>
      </div>
      <div class="imgWrapper">
        <img alt="" class="imgMain" :class="{show:indexIsActive(index),hide:!indexIsActive(index)}"
             v-for="(item, index) in list" :src="item.img">
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
section.block4 {
  .blockCenter {
    @include glx-center-block;

    .titleWrapper {
      .imgTitle {
        object-fit: none;
      }
    }

    .tabFloor {
      margin-top: 88px;

      display: flex;
      justify-content: space-between;

      margin-left: 213px;
      margin-right: 213px;

      .tab {
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 22px;
        color: #737373;
        line-height: 1;

        @mixin active {
          color: #1628D1;
        }

        &:hover {
          cursor: pointer;
          @include active;
        }

        &.active {
          @include active;
        }
      }
    }

    > .lineWrapper {
      margin-top: 20px;

      height: 3px;
      background: #E6E7F3;
      border-radius: 2px;

      margin-left: 213px;
      margin-right: 213px;

      .line {
        height: 100%;
        background: #1628D1;
        border-radius: 2px;

        display: flex;

        &.line1 {
          width: 176px;
        }

        &.line2 {
          width: 255px;
          margin-left: 235px;
        }

        &.line3 {
          width: 214px;
          margin-left: auto;
        }

      }
    }

    .imgWrapper {
      margin-top: 100px;

      > .imgMain {
        display: block;
        margin-left: auto;
        margin-right: auto;

        object-fit: none;

        @keyframes hide {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }

        @keyframes show {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @mixin hide() {
          display: none;
          animation-name: hide;
          animation-duration: 0.7s;
        }
        @mixin show() {
          display: block;
          animation-name: show;
          animation-duration: 0.7s;
        }

        &.hide {
          @include hide;
        }

        &.show {
          @include show;
        }
      }
    }
  }
}
</style>