<script setup lang="ts">
import type {News} from "~/composables/api";
//swiper 组件
import {Swiper, SwiperSlide} from 'swiper/vue';
// swiper css
import 'swiper/css';

import {DateTime} from "luxon";
import type {SwiperClass} from 'swiper/react';

const props = defineProps<{
  newsList: News[]
}>()

const newsList = ref<News[]>(props.newsList)
const listLength = computed(() => {
  return newsList.value.length
})

function formatTime(timeStr: string) {
  const time = DateTime.fromSQL(timeStr);
  return time.toFormat('yy . MM/dd')
}

const activeIndex = ref(2)
const percentage = ref(0)
const hoverIndex = ref<number>();

function indexIsActive(index: number): boolean {
  return index === activeIndex.value || index === hoverIndex.value;
}

function toNewsInfo(id: number) {
  navigateTo({
    path: `/news/${id}`
  })
}

function toNews() {
  navigateTo({
    path: '/news'
  })
}

const swiperRef = ref<SwiperClass>();

function initSwiper(swiper: SwiperClass) {
  swiperRef.value = swiper;
  myHandleSwiper(swiper)
}

function onSliderChange(swiper: SwiperClass) {
  myHandleSwiper(swiper)
}

function myHandleSwiper(swiper: SwiperClass) {
  const curIndex = swiper.realIndex;
  activeIndex.value = curIndex

  if (newsList.value.length) {
    const progress = (curIndex + 1) / listLength.value;
    percentage.value = Math.round(progress * 100)
  }
}

function onSlideToPrev() {
  swiperRef.value?.slidePrev();
}

function onSlideToNext() {
  swiperRef.value?.slideNext();
}
</script>

<template>
  <section class="block3">
    <div class="blockCenter1">
      <div class="titleWrapper">
        <img src="/img/home/block3-资讯中心.png" alt="" class="imgTitle">
      </div>
    </div>
    <div class="contentWrapper blockCenter2">
      <div class="divSwiper"></div>
      <swiper
          class="swiper"
          @after-init="initSwiper"
          loop
          slides-per-view="auto"
          :initial-slide="activeIndex"
          space-between="24"
          @active-index-change="onSliderChange"
      >
        <swiper-slide
            v-for="(item, index) in newsList" :key="index"
            @mouseenter="hoverIndex = index"
            @mouseleave="hoverIndex = undefined"
        >
          <div class="imgWrapper">
            <img :src="item.img" alt="" class="img">
          </div>
          <div class="content" :class="{active:indexIsActive(index)}">
            <div class="name">{{ item.name }}</div>
            <div class="time">{{ formatTime(item.time) }}</div>

            <img src="/img/home/block3-智能科技ai-active.png" alt="" class="imgCircle" v-if="indexIsActive(index)">
            <img src="/img/home/block3-智能科技ai.png" alt="" class="imgCircle" v-else>

            <div class="bottomFloor">
              <img src="/img/home/block3-阅读更多-active.png" alt="" class="iconArrow" @click="toNewsInfo(item.id)"
                   v-if="indexIsActive(index)">
              <img src="/img/home/block3-阅读更多.png" alt="" class="iconArrow" @click="toNewsInfo(item.id)" v-else>

              <div class="text" @click="toNewsInfo(item.id)">
                阅读更多
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="progressWrapper">
        <div class="btnSwitch" @click="onSlideToPrev">
          <div class="btnInner">
            <img src="/img/home/block3-左翻.png" alt="左翻">
          </div>
        </div>
        <el-progress :percentage="percentage" :color="'#1628D1'" :show-text="false" class="progress" :stroke-width="4"/>
        <div class="btnSwitch" @click="onSlideToNext">
          <div class="btnInner">
            <img src="/img/home/block3-右翻.png" alt="右翻">
          </div>
        </div>
      </div>
      <div class="moreNewsFloor" @click="toNews">
        <div class="text">
          更多资讯
        </div>
        <img src="/img/home/block3-更多资讯.png" alt="" class="iconArrow">
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">


section.block3 {
  overflow: hidden;

  > .blockCenter1 {
    @include glx-center-block;

    > .titleWrapper {
      font-size: 0;

      .imgTitle {
        object-fit: none;
      }
    }
  }

  > .blockCenter2 {
    @include glx-center-block;
  }

  > .contentWrapper {
    margin-top: 36px;
    position: relative;

    &::before {
      left: -24px;
      transform: translateX(-100%);
    }

    &::after {
      right: -24px;
      transform: translateX(100%);
    }

    //这个是swiper的占位
    .divSwiper {
      content: '';
      display: block;
      height: 226px + 358px;
      width: 100%;
    }

    :deep(.swiper) {
      width: 1920px - 360px;
      height: 226px + 358px;
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      right: -360px;

      overflow: unset !important;
      overflow-x: visible !important;

      .swiper-wrapper {
        overflow-x: visible !important;


        //.swiper-slide-next + .swiper-slide {
        //  opacity: 1;
        //}

        .swiper-slide {
          width: 384px;

          opacity: 0.4;

          &.swiper-slide-active, &.swiper-slide-next, &.swiper-slide-next + .swiper-slide {
            opacity: 1;
          }

          .imgWrapper {
            height: 226px;

            .img {
              width: 100%;
              height: 100%;
              object-fit: fill;
            }
          }

          .content {
            height: 358px;
            padding-top: 32px;
            position: relative;

            background: #FFFFFF;

            .name {
              font-family: PingFang SC;
              font-weight: 400;
              font-size: 20px;
              color: #000000;
              line-height: 1;

              margin-left: 28px;
              margin-right: 38px;

              @include glx-ellipsis();
            }

            .time {
              font-family: PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #000000;
              line-height: 1;

              margin-top: 28px;
              margin-left: 28px;
            }

            .imgCircle {
              display: block;
              margin-top: 47px;
              margin-left: 28px;
              object-fit: none;
            }

            .bottomFloor {
              position: absolute;
              bottom: 32px;
              display: flex;
              padding-left: 28px;

              align-items: center;

              .iconArrow {
                object-fit: none;

                &:hover {
                  cursor: pointer;
                }
              }

              > .text {
                font-family: PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #1628D1;
                line-height: 1;

                margin-left: 14px;

                &:hover {
                  cursor: pointer;
                  //这里要加 important 否则会被后面的 active 下的颜色 覆盖
                  color: $link-hover-color !important;
                }


              }
            }

            &.active {
              background: linear-gradient(15deg, #2A29A2, #3A33FB);

              .name, .time, .bottomFloor > .text {
                color: #FFFFFF;
              }
            }
          }
        }


      }
    }


    .progressWrapper {
      margin-top: 60px;
      margin-left: 204px;
      margin-right: 204px;

      position: relative;

      display: flex;
      align-items: center;

      > .btnSwitch {
        flex-shrink: 0;
        width: 58px;
        height: 58px;
        background: #949AD3;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          cursor: pointer;
          background: #1628D1;
        }

        .btnInner {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: 1px solid rgba(#FFFFFF, 0.4);

          display: flex;
          justify-content: center;
          align-items: center;

          .imgArrow {
            width: 14px;
            height: 24px;
            object-fit: none;
            display: block;
          }
        }
      }

      .progress {
        flex-grow: 1;
        margin: 0 27px;
      }
    }

    .moreNewsFloor {
      margin-top: 60px;
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      .text {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #010101;
        line-height: 1;
        margin-left: auto;
      }

      .iconArrow {
        display: block;
        object-fit: none;
        margin-left: 11px;
      }
    }
  }
}
</style>