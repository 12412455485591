<script setup lang="ts">

const newsListFetchCounter = ref(0)

function refreshNewsList() {
  newsListFetchCounter.value = newsListFetchCounter.value + 1
}

// const {data:newsList, refresh} = await uesApiFetchNewsList({page: 1, size: 20})

const {data: newsListStore} = await useAsyncData(
    'newsList',
    () => uesApiFetchNewsList({
      page: 1,
      size: 20
    }, {key: `newsList-${newsListFetchCounter.value}`})
        .then(res => res.data.value),
    {
      watch: [newsListFetchCounter],
    }
)

onMounted(() => {
  refreshNewsList()
})


</script>

<template>
  <section class="pageHome">
    <home-block1 class="block"/>
    <home-block2 class="block block2"/>
    <home-block3 class="block float-start" :news-list="newsListStore?.list || []"/>
    <home-block4 class="block block4"/>

    <glx-scroll-to-top/>
  </section>
</template>

<style scoped lang="scss">
.pageHome {

  .block {
    background-color: #F5F5F9;
  }

  .block2 {
    margin-top: 20px;
  }


  .block4 {
    padding-top: 86px;
    padding-bottom: 127px;
  }
}

</style>